export default {
  colors: {
    text: "#6D7770",
    background: "#FFFFF",
    beige: "#E2DACE",
    tan: "#9E9C91",
    grey: '#d0d0d0',
    navy: '#2B3147',
  },
  breakpoints: ['374px', '479px', '767px', '1023px', '1439px'],
}
