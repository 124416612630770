import {Theme} from 'styled-system';

export const breakpoints: string[] = ['374px', '479px', '767px', '1000px', '1250px', '1300px'];

export const colors = {
  text: '#6D7770',
  background: '#FFFFF',
  beige: '#E2DACE',
  tan: '#9E9C91',
  grey: '#d0d0d0',
  navy: '#2B3147',
};

export const defaultTheme: Theme = {
  breakpoints,
  colors: {
    ...colors,
  },
};
